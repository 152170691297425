import React from "react";
import { graphql } from "gatsby";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import { WhoWeAre } from "presentation/screens";
import type { QueryWhoWeAreProps } from "_types/graphql/iWhoWeAre";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import {
  getDataFooter,
  getDataMenu,
  getDataSearchMenu
} from "_utils/assemblePagesData";
import { WEBSITE_URL } from "_utils/constants";
import SEO from "presentation/Seo";

export default function PaginaQuemSomos({ data }: QueryWhoWeAreProps) {
  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });
  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });

  const footerData = getDataFooter({ data: data?.menu?.data?.body });

  const isExistSpecialties =
    data?.specialties?.nodes && data?.specialties?.nodes.length > 0;
  const specialties = isExistSpecialties
    ? data?.specialties.nodes.map(item => ({
        label: item?.data?.nome_da_especialidade.richText[0].text,
        icon: item?.data?.icone_da_especialidade?.url,
        url: item?.url
      }))
    : [];

  const dataSEO = {
    canonicalUrl: `${WEBSITE_URL}${data?.prismicQuemSomos?.data?.canonical_url?.text}`,
    noFollow: data?.prismicQuemSomos?.data?.no_follow,
    noIndex: data?.prismicQuemSomos?.data?.no_index,
    openGraph: data?.prismicQuemSomos?.data?.open_graph[0],
    telefone1: data?.contact?.data?.telefone_1?.text,
    email: data?.contact?.data?.email?.text,
    imagesPage: [],
    metaTags: [
      {
        property: "application-name",
        content: "GatsbySeo"
      },
      {
        property: "og:title",
        content:
          data?.prismicQuemSomos?.data?.open_graph[0]?.og_title?.text ??
          "sem conteúdo"
      },
      {
        property: "og:url",
        content: `${WEBSITE_URL}${data?.prismicQuemSomos?.uid}`
      },
      {
        property: "og:image",
        content: data?.prismicQuemSomos?.data?.open_graph[0]?.og_image?.url
      },
      {
        property: "og:type",
        content: "website"
      }
    ]
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        initialStyle="light"
        footerData={footerData}
        contact={data?.contact}
      >
        <WhoWeAre dataPage={data.prismicQuemSomos} />
      </TemplatePage>
    </ThemeProvider>
  );
}

export const query = graphql`
  query QuemSomos {
    contentContacts: prismicContato {
      data {
        telefone_1 {
          text
        }
        email {
          text
        }
        descricao {
          text
        }
      }
    }
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    searchExams: prismicBuscarExames {
      ...BuscarExamesFragment
    }
    contact: prismicContato {
      ...Contact
    }

    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }

    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          nome_da_especialidade {
            richText
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }

    prismicQuemSomos {
      id
      uid
      data {
        no_follow
        no_index
        open_graph {
          og_url {
            text
          }
          og_title {
            text
          }
          og_image {
            url
          }
          og_description {
            text
          }
        }
        canonical_url {
          text
        }
        titulo_da_pagina {
          richText
        }
        descricao_curta {
          richText
        }
        imagem_destacada {
          gatsbyImageData
        }
        body {
          ... on PrismicQuemSomosDataBodySecaoDeInstitucional {
            slice_type
            items {
              titulo_diferencial {
                richText
              }
              texto_diferencial {
                richText
              }
            }
            primary {
              titulo_diferencial {
                richText
              }
              texto_diferencial {
                richText
              }
            }
          }
          ... on PrismicQuemSomosDataBodySecaoConceitoDaEmpresa {
            slice_type
            primary {
              titulo_do_conceito {
                richText
              }
              imagem {
                gatsbyImageData
              }
              descritivo_do_conceito {
                richText
              }
            }
          }
          ... on PrismicQuemSomosDataBodySecaoSocioAmbiental {
            slice_type
            primary {
              titulo_socio_ambiental {
                richText
              }
              texto_socio_ambiental {
                richText
              }
            }
            items {
              nome_do_projeto
              imagem_do_projeto {
                gatsbyImageData
              }
              detalhe_do_projeto {
                richText
              }
            }
          }
          ... on PrismicQuemSomosDataBodySecaoDeLideranca {
            slice_type
            items {
              cargo
              foto {
                gatsbyImageData
              }
              mini_cv {
                richText
              }
              nome
            }
            primary {
              titulo_lideranca {
                richText
              }
            }
          }
          ... on PrismicQuemSomosDataBodySecaoDeParceiros {
            slice_type
            primary {
              titulo_parceiros {
                richText
              }
              texto_parceiros {
                richText
              }
              texto_botao_parceiros {
                text
              }
              link_botao_parceiros {
                url
                uid
              }
              imagem_parceiro___5 {
                alt
                gatsbyImageData
              }
              imagem_parceiro___4 {
                alt
                gatsbyImageData
              }
              imagem_parceiro___3 {
                alt
                gatsbyImageData
              }
              imagem_parceiro___2 {
                alt
                gatsbyImageData
              }
              imagem_parceiro___1 {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
